import { ComponentRef, Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { FabButtonSubMenuComponent } from './fab-button-sub-menu.component';
import { OverlayRefComponent } from './overlay-ref';
import { FabButtonService } from './fab-button.service';

interface FabButtonDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

const DEFAULT_CONFIG: FabButtonDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel'
};

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  allDialogs: OverlayRefComponent[] = [];

  constructor(
    private fabButtonService: FabButtonService,
    private overlay: Overlay) {
    this.fabButtonService.closeDialog.subscribe(() => {
      this.close()
    })
  }

  open(config: FabButtonDialogConfig = {}) {
    // Override default configuration
    const dialogConfig = {...DEFAULT_CONFIG, ...config};
    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // We let subscribers know when backdrop (with submenu) was detached
    let onDetachedBackdropSub = overlayRef.detachments().subscribe(() => {
      this.fabButtonService.isSubMenuOpen.next(false);
      onDetachedBackdropSub.unsubscribe();
    });

    let onCloseBackdropSub = overlayRef.backdropClick().subscribe(_ => {
      this.fabButtonService.isSubMenuOpen.next(false);
      dialogRef.close();
      onCloseBackdropSub.unsubscribe();
    });

    // Instantiate remote control
    const dialogRef = new OverlayRefComponent(overlayRef);
    this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    this.allDialogs.push(dialogRef)

    return dialogRef;
  }

  close() {
    if (this.allDialogs.length > 0) {
      this.allDialogs.forEach(dialog => dialog.close())
    }
  }

  private createOverlay(config: FabButtonDialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: FabButtonDialogConfig, dialogRef: OverlayRefComponent) {
    const containerPortal = new ComponentPortal(FabButtonSubMenuComponent, null);
    const containerRef: ComponentRef<FabButtonSubMenuComponent> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private getOverlayConfig(config: FabButtonDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
                                 .global()
                                 .centerHorizontally()
                                 .centerVertically();

    /**
     * NoopScrollStrategy: does nothing
     CloseScrollStrategy: automatically closes the overlay when scrolling
     BlockScrollStrategy: blocks page scrolling
     RepositionScrollStrategy: will reposition the overlay element on scroll
     */

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy
    });
  }
}
