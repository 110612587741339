<ng-container *ngIf="isReady">

<!--Always have activity-->
<div class="sub-menu-label"
     [@openClose]="isOpen"
     (click)="onClickOption('/health-plan/new')"
     role="button"
     [ngStyle]="{'bottom.px': bottomDistance+10, 'right.px':
rightLabelPadding}">{{'healthPlan.activities' | translate}}</div>
<button (click)="onClickOption('/health-plan/new')" mat-fab aria-label="activity-button"
        [@openClose]="isOpen"
        class="sub-menu-button"
        [ngStyle]="{'bottom.px': bottomDistance,'right.px': rightButtonPadding}">
  <mat-icon class="icon-color-blue"
            [svgIcon]="'low-activity'" style="transform: scale(1.5); stroke-width: 1.7px;"></mat-icon>
</button>
<!--Goal measurement links-->
<ng-container *ngIf="allGoals.length > 0">
  <ng-container *ngFor="let goal of allGoals; index as i">

    <ng-container *ngIf="goal.typeId == goalTypes.BLOOD_PRESSURE">
      <ng-content *ngTemplateOutlet="subMenuButton;context:{iteration:i, translate:  'diary.blood-pressure',
      link: '/diary/blood-pressure/new', icon: 'blood-pressure', scale: 1.5}"></ng-content>
    </ng-container>

    <ng-container *ngIf="goal.typeId == goalTypes.PEF">
      <ng-content *ngTemplateOutlet="subMenuButton;context:{iteration:i, translate:  'diary.asthma-pef',
      link: '/diary/asthma-pef/new', icon: 'asthma', scale: 1.5}"></ng-content>
    </ng-container>

    <ng-container *ngIf="goal.typeId == goalTypes.WEIGHT">
      <ng-content *ngTemplateOutlet="subMenuButton;context:{iteration:i, translate:  'diary.measurements',
      link: '/diary/measurements/new', icon: 'measurements', scale: 1.8}"></ng-content>
    </ng-container>

    <ng-container *ngIf="goal.typeId == goalTypes.BLOOD_SUGAR">
      <ng-content *ngTemplateOutlet="subMenuButton;context:{iteration:i, translate:  'diary.blood-sugar',
      link: '/diary/blood-sugar/new', icon: 'blood-sugar', scale: 1.8}"></ng-content>
    </ng-container>

  </ng-container>
</ng-container>

</ng-container>


<!--BUTTON WITH LABEL-->
<ng-template #subMenuButton let-Iteration="iteration" let-Translate="translate" let-Link="link" let-Icon="icon"
             let-Scale="scale">
  <div class="sub-menu-label"
       [@openClose]="isOpen"
       (click)="onClickOption(Link)"
       role="button"
       [ngStyle]="{'bottom.px': (bottomDistanceMeasure+10)+(Iteration * 70), 'right.px':
      rightLabelPadding}">
    {{Translate | translate}}
  </div>
  <button (click)="onClickOption(Link)" mat-fab aria-label="button" #button
          [@openClose]="isOpen"
          class="sub-menu-button"
          [ngStyle]="{'bottom.px': bottomDistanceMeasure + (Iteration * 70), 'right.px': rightButtonPadding}">
    <mat-icon class="icon-color-blue" [svgIcon]="Icon"
              [style.transform]="'scale('+Scale+')'"></mat-icon>
  </button>
</ng-template>
